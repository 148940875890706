import React from 'react';
import { WebStore } from 'types/webStore';
import Contentful from 'blocks/Contentful';
import { Menu } from 'blocks/MenuNavigation/types';
import { IrisShopstoryProvider } from '../../shopstory/IrisShopstoryProvider';
import { LandingPageData } from 'services/contentful';
import { ShopstoryMetadataProvider } from '@shopstory/react';

type Props = {
  footer: {
    color: string;
    backgroundColor: string;
    links: any[];
    webStore: WebStore;
  };
  homepage: LandingPageData;
  menuNavigation: Menu;
  webStore: WebStore;
};

export default function HomePage(props: Props) {
  const { homepage } = props;

  if (!props.homepage.shopstoryMetadata) {
    throw new Error("If you're using Shopstory blocks, you must pass meta");
  }

  return (
    <IrisShopstoryProvider>
      <ShopstoryMetadataProvider meta={homepage.shopstoryMetadata}>
        <Contentful blocks={homepage.blocks} animateTypographies />
      </ShopstoryMetadataProvider>
    </IrisShopstoryProvider>
  );
}
